import PropTypes from 'prop-types';
import React from 'react';

import { Flex, H1, H4 } from '../styled';
import { headerHeight, jumbotronHeight } from '../theme/measurements';
import BaseLayout from './BaseLayout';
import Box from '../Box';
import Footer from '../footer';
import Header from '../header';

const DocPageLayout = ({ children, title, subtitle, backgroundSrc }) => (
  <BaseLayout seoProps={{ title }}>
    <Header />
    <Flex
      as="section"
      flexDirection="column"
      justifyContent="center"
      mt={headerHeight}
      height={jumbotronHeight}
      px={30}
      sx={{ background: `center / cover url(${backgroundSrc})`, backgroundColor: 'indigo' }}
    >
      <Box textAlign="center" color="white">
        <H1>{title}</H1>
        <H4 mt={15} mx="auto" maxWidth={600}>
          {subtitle}
        </H4>
      </Box>
    </Flex>
    {children}
    <Footer />
  </BaseLayout>
);

DocPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundSrc: PropTypes.string,
};
DocPageLayout.defaultProps = {
  title: '',
  subtitle: '',
  backgroundSrc: '',
};

export default DocPageLayout;

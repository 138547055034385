import React from 'react';

import { Outer, Inner, P, Box, DocPage } from '../components/styled';
import DocPageLayout from '../components/layout/DocPageLayout';

// prettier-ignore
const SupportPolicyPage = () => (
  <DocPageLayout title="Support Policy" subtitle="Posting Date: November 4, 2018">
    <Outer>
      <Inner>
        <DocPage>
          <Box mb={40}>
            <P>
              This support policy (the “<strong>Policy</strong>”) sets forth the policies and procedures with respect to services (the “<strong>Service</strong>”) provided by Sprintlio Inc. (“<strong>Sprintlio</strong>”) to a customer (“<strong>Customer</strong>”) pursuant to the subscription agreement between Sprintlio and Customer (a “<strong>Customer Agreement</strong>”).
            </P>
          </Box>
          <h2>Summary</h2>
          <p>
            As further described below, Sprintlio will use commercially reasonable efforts to provide Customer with: (i) 99.9% availability (the “<strong>Service Availability</strong>”); and (ii) provide standard support to Customer.
          </p>

          <h2>Implementation</h2>
          <p>
            Upon payment of the applicable fees, Sprintlio agrees to use commercially reasonable efforts to provide standard implementation assistance for the Service as set forth in the applicable Order Form (“<strong>Implementation Assistance</strong>”). If Sprintlio provides Implementation Assistance in excess of any agreed-upon hours estimate, or if Sprintlio provides additional services beyond those previously agreed to and/or set forth in the applicable Order Form, Customer will pay Sprintlio for such additional services at Sprintlio’s then-current hourly rates for consultation.
          </p>

          <h2>Availability</h2>
          <p>If the Service becomes substantially unavailable to Customer due to defects with the Service, Sprintlio will respond to Customer in accordance with the timelines set out under the heading ‘Target Response and Resolution Times’ below. The Service Availability will be measured on a monthly basis, with all hours weighted equally, and will exclude reasonable scheduled downtime for system maintenance, delays or unavailability caused by Customer, as well as any downtime or performance issues resulting from third party connections, services or utilities or other reason beyond Sprintlio’s control (including without limitation, acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems (other than those involving Sprintlio employees), computer, telecommunications, Internet service provider or hosting facility failures or delays involving hardware, software or power systems not within Sprintlio’s possession or reasonable control, and denial of service attacks).</p>

          <h2>Support</h2>
          <p>
            Unless Customer selects a support plan on its order form, during the term of the Customer Agreement, Sprintlio will use reasonable commercial efforts to provide email support and maintenance services for the Service during Sprintlio’s normal business hours. It is the Customer’s sole responsibility to provide Sprintlio with access to its facilities and resources in order to provide the Service, including remote access. Any other support services are outside of the scope of this policy and must be separately agreed in writing by Customer and Sprintlio. Customer must designate a support contact (“<strong>Designated Support Contact</strong>”), and all support requests from Customer must come to Sprintlio through such Designated Support Contact. Customer may update the Designated Support Contact by providing written notice to Sprintlio.
          </p>

          <h2>Target Response and Resolution Times</h2>
          <p>In the event the Service becomes substantially unavailable to Customer due to defects with the Service, as more particularly described under the heading ‘Availability’ above, Sprintlio will respond to Customer in accordance with the timelines set out in the table below. The following priority codes are used to distinguish applicable target response and resolution times, depending on the type of incident:</p>
          <ul>
            <li>1 – incidents such as not serving data or serious performance degradation;</li>
            <li>2 – incidents such as loss of high availability performance degradation, intermittent software faults or non-critical network degradations;</li>
            <li>3 – incidents or defects that do not materially impact Customer’s business; and</li>
            <li>4 – any requests for information or other administrative requests.</li>
          </ul>
          <p>The corresponding target response and resolution times below are measured from when the incident is opened by support. If an incident is not resolved within the applicable timeframe, continuous effort will be applied until the problem is resolved.</p>
          <table>
            <thead>
              <tr>
                <th>Priority Code</th>
                <th>
                  Initial Response
                  <br />
                  (Telephone or Email)
                </th>
                <th>Target Response Time</th>
                <th>Report Frequency</th>
                <th>Target Resolution Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>1</th>
                <td>30 minutes or less</td>
                <td>2 hours</td>
                <td>Every 60 minutes</td>
                <td>5 hours</td>
              </tr>
              <tr>
                <th>2</th>
                <td>1 hour or less</td>
                <td>3 hours</td>
                <td>Every 2 hours</td>
                <td>8 hours</td>
              </tr>
              <tr>
                <th>3</th>
                <td>4 hours or less</td>
                <td>8 hours</td>
                <td>Every 6 hours</td>
                <td>5 calendar days</td>
              </tr>
              <tr>
                <th>4</th>
                <td>8 hours</td>
                <td>Next business day</td>
                <td>Weekly</td>
                <td>10 calendar days</td>
              </tr>
            </tbody>
          </table>
          <p>For clarity, the target resolution times set out herein presume that the Customer will cooperate with Sprintlio to resolve incidents if and as required. Sprintlio cannot guarantee resolution times, but will use its best efforts to meet applicable targets.</p>

          <h2>Service Credits</h2>
          <p>
            If the Service is unavailable to Customer due to defects with the Service such that the Service Availability requirement is not met (such determination to be made on a monthly basis), then, Sprintlio will, at its sole discretion, and provided that Customer notifies Sprintlio support within seventy-two (72) hours from the occurrence of the relevant incident, provide Customer with a credit (“<strong>Service Credit</strong>”) for the subsequent Service billing cycle as follows:
          </p>
          <ul>
            <li>97.5%-99.8% Service Availability: 5% of monthly fee for Service Credit</li>
            <li>95%-97.5% Service Availability: 10% of monthly fee for Service Credit</li>
            <li>95% Service Availability: 20% of monthly fee for Service Credit</li>
          </ul>
          <p>The Service Credit shall constitute Customer’s sole and exclusive remedy (and Sprintlio’s sole liability) hereunder, and all Service Credits provided hereunder are non-refundable. If Customer elects not to renew the Customer Agreement, such that a Service Credit cannot be applied, Customer will have the option to receive up to one additional month of the applicable component of the Service at no cost as its sole remedy in lieu of receiving such Service Credit. In such case, Sprintlio will extend any necessary licenses granted to the Customer in connection with the Service for the duration of such additional period.</p>
        </DocPage>
      </Inner>
    </Outer>
  </DocPageLayout>
);

export default SupportPolicyPage;
